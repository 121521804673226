@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.cdnfonts.com/css/futura-pt');

:root {
  form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");
}

@font-face {
  font-family: Montserrat-light;
  src: url('assets/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('assets/fonts/Montserrat-Bold.ttf');
}


main h1 {
  font-family: Montserrat-Bold;
  font-size: 1.7rem;
  margin: 1rem 2rem 2rem 0rem;
}

main h3 {
  font-family: Montserrat-light;
  font-size: 0.9rem;
  margin: 1rem 2rem 2rem 0rem;
  color: #707070
}

main.sondage h3 {
  margin: 1rem 2rem 1rem 0rem;
}

.sondage .form-check {
  padding-left: 1rem;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;

}

.card.eventbox {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 25px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.homepage_screen {

  background: url('assets/images/backgroundfond.jpg')no-repeat;
  background-size: cover;
  /* height: 114vh; */
}

.formcontainer {
  max-width: 85%;
  background-color: white;
  box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.49);

  border-radius: 25px;
  margin-top: 11px;
  margin: auto;
}

.bar {
  border-top: 2px solid #707070;
  margin-bottom: 1rem;
}

.blur {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.3);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.21);
  height: 149px;
  background: #ffffff5c;
  position: absolute;
  width: 100%;
}

.header {
  display: flex;
  /* border: 1px solid black; */
  width: 100%;
  height: 150px;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  /* background: #ffffff5c; */
  z-index: 1;
  justify-content: space-around;

}

.header .block1 {
  /* border: solid 1px red; */
  width: 25%;
}

.header .block2 {
  /* width: 15%; */
  /* border: solid 1px red; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  justify-content: flex-start;
  /* margin-left: 2rem; */
  align-content: center;
  height: 62px;
  margin-block: auto;
}

.header h3 {
  color: #fff;
  cursor: pointer;
  /* font-family: Montserrat; */
  /* font-size: 20px; */
  font-weight: 500;
  letter-spacing: 0;
  font-family: Montserrat-light;
  font-size: 1.4rem;
  margin: 2rem 4rem 2rem 0rem;
  text-transform: uppercase;

}

.swal2-popup {
  width: 75% !important;
  margin-top: 3rem;
}

.swal2-html-container {
  margin: 3em 1.6em 0.3em;
  padding: 7px;
  color: #005978;
  text-align: left;
  font-family: Montserrat-light;
}

.swal2-html-container h2 {
  text-align: center;
}

.header .block2 img {
  width: 150px;
  /* margin-left: 1rem; */
}

.Home__screen {
  padding: 1vw 10vw;
}

.Home__screen .nomprenom,
.Home__screen .email_structure {
  display: flex;
  justify-content: space-between;
  margin: auto;

}

.Home__screen .nomprenom .form-control,
.Home__screen .email_structure .form-control,
.Home__screen .card .form-control {
  border-radius: 25px;
  -webkit-box-shadow: inset 0px 3px 6px 0px rgb(0 0 0 / 15%);
  ;
  box-shadow: inset 0px 3px 6px 0px rgb(0 0 0 / 15%);
  ;
  color: #005978;
  opacity: 1;
  font-family: 'Futura PT', sans-serif;
  font-weight: 600;
  background-color: #efefef;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #005978;
  opacity: 1;
  font-family: 'Futura PT', sans-serif;
  font-weight: 600;
  /* Firefox */
}

.nomprenom .form-group,
.email_structure .form-group {
  width: 49%;
}

.removefriend {
  background: url("assets/images/Body-btn-croix.png")no-repeat center center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 1px 1px 11px rgb(0 0 0 / 30%);
  margin-left: auto;
  margin-bottom: 1rem;
}


.Inviter_partic {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  color: #005978;
  opacity: 1;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
  ;
  padding: 0.5rem 1rem;
  margin: 0 1rem 0rem 0rem;
  word-wrap: break-word;
  max-width: 60%;
  margin-bottom: 2rem;
}

.Inviter_partic img {
  width: 30px;
  /* height: 50px; */
  /* border-radius: 25px; */
  /* box-shadow: 1px 1px 11px rgb(0 0 0 / 30%);
  margin-left: auto;
  margin-bottom: 1rem; */
}

.eventbox {
  padding: .75rem 2rem;
  color: #005978;
  opacity: 1;
  font-family: 'Futura PT', sans-serif;
  font-weight: 700;
  /* height: 10vh; */
  font-size: 13px;
  /* min-width: 14vw; */
  /* padding: ; */
  cursor: pointer;
}

.eventbox .card-title {
  margin-bottom: 0;
}

.eventbox .card-text {
  margin-bottom: 0;
}

.eventchecked {
  background-color: #c5e33b;
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
}

.event_nonchecked {
  background-color: transparent;
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
}

.event_nonchecked.notAvailable.enabled,
.eventchecked.notAvailable.enabled {
  cursor: no-drop;
  background-color: rgba(126, 117, 117, 0.5);
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
}

.event_nonchecked.disabled,
.eventchecked.disabled {
  cursor: no-drop;
  background-color: rgba(126, 117, 117, 0.5);
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
}



.form-check {
  padding-left: 0rem;
}

.card-body {
  padding: 0rem;
}

.Eventboxes .form-check-label {
  font-family: Montserrat-light;
  font-size: 0.9rem;
  margin: -0.75rem 0rem;
  color: #707070
}

.form-check-label {
  font-family: Montserrat-light;
  font-size: 0.9rem;
  margin: 1rem 1rem 1rem 0rem;
  color: #707070;
  cursor: pointer;
}

input[type="checkbox"] {
  background-color: #e5e6e6;
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-top: 2px;
}

input[type="checkbox"]:checked {

  cursor: pointer;
  accent-color: hsl(101deg 53% 52%);
}

/*
.sondage input[type='radio'] {
  display: none;
}

.sondage label {
  color: #666;
  font-weight: normal;
}

.sondage label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #004c97;
  background-color: transparent
}

.sondage input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: hsl(101deg 53% 52%);
} */

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #7B1FA2;
}

.custom-control-input:checked~.custom-control-label.red::before {
  background-color: red;
}

.custom-control-input:checked~.custom-control-label.green::before {
  background-color: green;
}

@media screen and (max-width: 870px) {
  /* .header .block2 {
    margin-left: 0rem !important;
    width: 0%;
  } */

  .eventbox {
    padding: 1rem;
  }

  .nomprenom,
  .email_structure {
    flex-direction: column;
  }

  .nomprenom .form-group,
  .email_structure .form-group {
    width: 100%;
  }


}

.submit_form {
  padding: .25rem 1rem;
}

.subscribe_btn {
  display: flex;
  justify-content: flex-end;
  margin-right: -9vw;
}

.subscribe_btn .subscribebtn {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  color: #005978;
  opacity: 1;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
  ;
  padding: 0.5rem 1rem 0.5rem 2rem;
  margin: 0 1rem 0rem 0rem;
  word-wrap: break-word;
  min-width: 14vw;
  margin-bottom: 2rem;
  border: none;
}

.subscribe_btn img {
  width: 30px;
  margin-left: auto;
}


.swal-modal {
  border-radius: 25px;

}

.swal-text {
  text-align: center;
  color: hsl(101deg 53% 52%);

}

.swal-button {
  color: #005978;
  opacity: 1;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
  ;
  background-color: transparent;
}

.subscribe_btn .subscribebtn:hover,
.Inviter_partic:hover,
.eventbox.enabled.available:hover {
  background-color: hsl(71deg 74% 56%);
}

/* .nomprenom .form-control:focus,
.email_structure .form-control:focus,
.card .form-control:focus {
  color: red !important;
} */
input:-webkit-autofill {
  -webkit-text-fill-color: #005978;
  background-color: white !important;
  box-shadow: inset 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px -3px rgb(0 0 0 / 68%);
  ;
  -webkit-box-shadow: inset 1px 5px 14px -7px rgb(0 0 0 / 76%),
    -1px 7px 9px -5px rgb(0 0 0 / 68%) !important;
}