body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


p.message-error {
  color: #bf1650;
}

p.message-error::before {
  display: inline;
  content: "⚠ ";
}